<form class="form-signin" (ngSubmit)="onForgot()">
    <img class="mb-4 logo-login" src="assets/image/logo-big.png">
    <p>Forgot Password</p>
    <p>Just fill in your email blow. Due to security reasons we can not confirm an account exists for the provided
        information</p>
    <div class="input-group">
        <div class="input-group-prepend place-self-center">
            <span class="input-group-text" id="validationTooltipUsernamePrepend"><i class="fa fa-envelope"
                    aria-hidden="true"></i></span>
        </div>
        <input type="email" class="form-control" id="validationTooltipEmail" placeholder="Email"
            aria-describedby="validationTooltipUsernamePrepend" [(ngModel)]="forgotPass.email" 
            [ngModelOptions]="{standalone: true}" required>
        <div class="invalid-tooltip">
            Please choose a unique and valid email.
        </div>
    </div>

    <button class="btn btn-lg btn-primary btn-block mt-4  btn-forgot-password " type="submit">Reset My Password</button>
</form>
<a [routerLink]="['/auth/signin']">Login</a>
<!-- <div class="infor-login">
    <p><i class="fa fa-envelope" aria-hidden="true"></i>{{ contact }}</p>
    <p><i class="fa fa-volume-control-phone" aria-hidden="true"></i>{{ phone }}</p>
</div> -->