/* tslint:disable */

/**
 */
export class Job {
    job?: string;
    cpu?: number;
    user_name?: string;
    status?: string;
    date?: string;
    role?: string;
    uuid?: string;
}
