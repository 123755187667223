<form class="form-signin" (ngSubmit)="onReset()">
  <img class="mb-4 logo-login"src="./assets/image/logo-big.png">
  <p>Reset password</p>
  <div class="input-group">
    <div class="input-group-prepend place-self-center">
      <span class="input-group-text"><i class="fa fa-unlock" aria-hidden="true"></i></span>
    </div>
    <input type="password" class="form-control" placeholder="New Password" [(ngModel)]="forgotPass.password"
      [ngModelOptions]="{standalone: true}" required>
    <div class="invalid-tooltip">
      Please choose a unique and valid username.
    </div>
  </div>
  <div class="input-group">
    <div class="input-group-prepend place-self-center">
      <span class="input-group-text"><i class="fa fa-clipboard" aria-hidden="true"></i></span>
    </div>
    <input type="password" class="form-control" placeholder="Confirm password"
      [(ngModel)]="forgotPass.password_confirmation" [ngModelOptions]="{standalone: true}" required>
    <div class="invalid-tooltip">
      Please choose a unique and valid password.
    </div>
  </div>
  <button class="btn btn-lg btn-primary btn-block mt-4  btn-forgot-password" type="submit">Change password</button>
</form>
<!-- <div class="infor-login">
  <p><i class="fa fa-envelope" aria-hidden="true"></i>{{ contact }}</p>
  <p><i class="fa fa-volume-control-phone" aria-hidden="true"></i>{{ phone }}</p>
</div> -->