import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../api/services';
import { ForgotPass } from '../../api/models';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../../shared/common.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {

  public forgotPass: ForgotPass;
  public contact: string = environment.contact;
  public phone: string = environment.phone;

  constructor(
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private commonService: CommonService
  ) { 
    this.forgotPass = new ForgotPass();
  }

  ngOnInit() {
  }

  onForgot() {
    if (!this.forgotPass.email ) {
      swal('Warning!', this.translate.instant('user.login.email.required'), 'warning');
      return;
    }
    if (this.commonService.isEmailInvalid(this.forgotPass.email)) {
      swal('Warning!', this.translate.instant('user.login.email.invalid'), 'warning');
      return;
    }
    this.spinner.show();
    this.authService.forgotPass(this.forgotPass).subscribe(
      res => {
        this.spinner.hide();
        swal({ type: 'success', title: 'Successful!', text: res['data'] });
      },
      err => {
        this.spinner.hide();
        swal({ type: 'error', title: 'Error!', text: err.error.error });
      }
    );
  }
}