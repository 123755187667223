/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Material } from '../models/material';
import { Project } from '../models/project';
import { TypeAny } from '../models/type-any';


@Injectable()
export class MaterialsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   */
  getMaterialListResponse(): Observable<HttpResponse<Material[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/materials`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Material[] = null;
        _body = _resp.body as Material[]
        return _resp.clone({body: _body}) as HttpResponse<Material[]>;
      })
    );
  }

  /**
   */
  getMaterialList(): Observable<Material[]> {
    return this.getMaterialListResponse().pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - Created material
   */
  createMaterialResponse(body?: Material): Observable<HttpResponse<Material>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/materials`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Material = null;
        _body = _resp.body as Material
        return _resp.clone({body: _body}) as HttpResponse<Material>;
      })
    );
  }

  /**
   * @param body - Created material
   */
  createMaterial(body?: Material): Observable<Material> {
    return this.createMaterialResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param materialId - undefined
   */
  getMaterialByIdResponse(materialId: string): Observable<HttpResponse<Material>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/materials/${materialId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Material = null;
        _body = _resp.body as Material
        return _resp.clone({body: _body}) as HttpResponse<Material>;
      })
    );
  }

  /**
   * @param materialId - undefined
   */
  getMaterialById(materialId: string): Observable<Material> {
    return this.getMaterialByIdResponse(materialId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param materialId - undefined
   * @param body - Update materials object
   */
  updateMaterialResponse(params: MaterialsService.UpdateMaterialParams): Observable<HttpResponse<Project>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/materials/${params.materialId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Project = null;
        _body = _resp.body as Project
        return _resp.clone({body: _body}) as HttpResponse<Project>;
      })
    );
  }

  /**
   * @param materialId - undefined
   * @param body - Update materials object
   */
  updateMaterial(params: MaterialsService.UpdateMaterialParams): Observable<Project> {
    return this.updateMaterialResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param materialId - undefined
   */
  deleteMaterialResponse(materialId: string): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/materials/${materialId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param materialId - undefined
   */
  deleteMaterial(materialId: string): Observable<TypeAny> {
    return this.deleteMaterialResponse(materialId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  uploadFractureCoefficientResponse(body?: TypeAny): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/materials/upload/csv`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  uploadFractureCoefficient(body?: TypeAny): Observable<TypeAny> {
    return this.uploadFractureCoefficientResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  uploadMaterialTableResponse(body?: TypeAny): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/materials/upload/table`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  uploadMaterialTable(body?: TypeAny): Observable<TypeAny> {
    return this.uploadMaterialTableResponse(body).pipe(
      map(_r => _r.body)
    );
  }}

export module MaterialsService {
  export interface UpdateMaterialParams {
    materialId: string;
    body?: Material;
  }
}
