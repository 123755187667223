<app-header-project [projectname]="project.title"></app-header-project>
<div class="row align-items-stretch">
  <div class="col-md-2 main-menu">
    <nav>
      <ul>
        <li class='sub-menu' dir="1" value="1">
          <a>
            <img src="./assets/image/01 geometry.png">
            <b>Geometry</b>
            <div class='fa fa-angle-down right arrown' [ngStyle]="{'display': !inputData.menu_check?.geometry.plate && !inputData.menu_check?.geometry.cylinder && !inputData.menu_check?.geometry.vessel && !inputData.menu_check?.geometry.expert_geo ? 'block' : 'none' }"></div>
            <i class="fa fa-check-circle right check-all" aria-hidden="true" [ngStyle]="{'display': inputData.menu_check?.geometry.plate || inputData.menu_check?.geometry.cylinder || inputData.menu_check?.geometry.vessel || inputData.menu_check?.geometry.expert_geo ? 'block' : 'none' }"></i>
          </a>
          <ul class="sub-item" [ngStyle]="{'display': init_load == 'geometry' || init_load == 'plate' || init_load == 'cylinder' || init_load == 'vessel' || init_load == 'expert' ? 'block' : 'none' }">
            <li [ngClass]="init_load == 'plate' ? 'active' : ''"
              (click)="onClickGeometry('plate')">
              <img class="symbol-l" src="./assets/image/L.png">
              <a class="dropright" rel="plate">Plate 
                <i class="fa fa-check-circle right geometry-page plate-page" [ngClass]="inputData.menu_check?.geometry.plate ? 'actived' : ''" aria-hidden="true"></i>
              </a>
            </li>
            <li [ngClass]="init_load == 'cylinder' ? 'active' : ''"
              (click)="onClickGeometry('cylinder')">
              <img class="symbol-l" src="./assets/image/L.png">
              <a class="dropright" rel="cylinder">Cylinder 
                <i class="fa fa-check-circle right geometry-page cylinder-page" [ngClass]="inputData.menu_check?.geometry.cylinder ? 'actived' : ''" aria-hidden="true"></i>
              </a>
            </li>
            <li [ngClass]="init_load == 'vessel' ? 'active' : ''" (click)="onClickGeometry('vessel')" >
              <img class="symbol-l" src="./assets/image/L.png">
              <a class="dropright" rel="vessel">Vessel 
                <i class="fa fa-check-circle right geometry-page vessel-page" [ngClass]="inputData.menu_check?.geometry.vessel ? 'actived' : ''" aria-hidden="true"></i>
              </a>
            </li>
            <li [ngClass]="init_load == 'expert' ? 'active' : ''" (click)="onClickGeometry('expert')" *ngIf="security.role == 'admin' || expertAccess">
              <img class="symbol-l" src="./assets/image/L.png">
              <a class="dropright" rel="expert">Expert 
                <i class="fa fa-check-circle right geometry-page expert-page" [ngClass]="inputData.menu_check?.geometry.expert_geo ? 'actived' : ''" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </li>
        <li class='sub-menu' [ngClass]="init_load == 'numeric' ? 'active' : ''" (click)="onClickNumeric()" value="2">
          <a class="dropright" rel="numerics">
            <img src="./assets/image/02 numerics.png">
            <b>Numerics</b>
            <i class="fa fa-check-circle right numeric-page" aria-hidden="true"
            [ngClass]="inputData.geometry.shape && inputData.menu_check?.numeric[inputData.geometry.shape] ? 'actived' : ''"></i>
          </a>
        </li>
        <li class='sub-menu' [ngClass]="init_load == 'material' ? 'active' : ''" (click)="onClickMaterial()" value="3">
          <a class="dropright" rel="steel">
            <img src="./assets/image/03 material.png">
            <b>Materials</b>
            <i class="fa fa-check-circle right material-page" aria-hidden="true" [ngClass]="inputData.menu_check?.material ? 'actived' : ''"></i>
          </a>
        </li>
        <li class="sub-menu menu-boundary" dir="{{ inputData.menu_check.material }}" value="4">
          <a>
            <img src="./assets/image/04 boundary condition.png">
            <b>Boundary Conditions</b>
            <div class='fa fa-angle-down right arrown' *ngIf="!inputData.menu_check?.geometry.expert_geo" [ngStyle]="{'display': !inputData.menu_check?.boundary.symmetry_plane || !inputData.menu_check?.boundary.pressure ? 'block' : 'none' }"></div>
            <i class="fa fa-check-circle right check-all" *ngIf="!inputData.menu_check?.geometry.expert_geo" aria-hidden="true" [ngStyle]="{'display': inputData.menu_check?.boundary.symmetry_plane && inputData.menu_check?.boundary.pressure ? 'block' : 'none' }"></i>
            <!-- expert -->
            <div class='fa fa-angle-down right arrown' *ngIf="inputData.menu_check?.geometry.expert_geo" [ngStyle]="{'display': !checkAllFace() ? 'block' : 'none' }"></div>
            <i class="fa fa-check-circle right check-all" *ngIf="inputData.menu_check?.geometry.expert_geo" aria-hidden="true" [ngStyle]="{'display': checkAllFace() ? 'block' : 'none' }"></i>
            <!-- end expert -->
          </a>
          <ul class="sub-item" *ngIf="!inputData.menu_check?.geometry.expert_geo" [ngStyle]="{'display': init_load == 'boundary' || init_load == 'pressure' || init_load == 'symmetry-plane' ? 'block' : 'none' }">
            <li [ngClass]="init_load == 'symmetry-plane' ? 'active' : ''" (click)="onClickBoundary('symmetry-plane')">
              <img class="symbol-l" src="./assets/image/L.png">
              <a class="dropright" rel="symmetry">Symmetry plane 
                <i class="fa fa-check-circle right boundary-page symmetry-page" aria-hidden="true" [ngClass]="inputData.menu_check?.boundary.symmetry_plane ? 'actived' : ''"></i>
              </a>
            </li>
            <li [ngClass]="init_load == 'pressure' ? 'active' : ''" (click)="onClickBoundary('pressure')">
              <img class="symbol-l" src="./assets/image/L.png">
              <a class="dropright" rel="pressure">Pressure 
                <i class="fa fa-check-circle right boundary-page pressure-page" aria-hidden="true" [ngClass]="inputData.menu_check?.boundary.pressure ? 'actived' : ''"></i>
              </a>
            </li>
          </ul>
          <!-- expert -->
          <ul class="sub-item" *ngIf="inputData.menu_check?.geometry.expert_geo" [ngStyle]="{'display': init_load == 'face' ? 'block' : 'none' }">
            <li *ngFor="let item of faces" [ngClass]="item.id == faceid ? 'active' : ''" (click)="onClickExpert(item.id)">
              <img class="symbol-l" src="./assets/image/L.png">
              <a class="dropright" rel="expert">{{ convertName(item.name) }}
                <i class="fa fa-check-circle right boundary-page {{ 'face_' + item.id }}" aria-hidden="true" [ngClass]="inputData.menu_check?.boundary.expert_bcs[item.id] ? 'actived' : ''"></i>
              </a>
            </li>
          </ul>
          <!-- end expert -->
        </li>
        <li class='sub-menu' [ngClass]="init_load == 'mesh' ? 'active' : ''" (click)="onClickMesh()" value="5">
          <a class="dropright" rel="mesh">
            <img src="./assets/image/05 mesh.png">
            <b>Mesh</b>
            <i class="fa fa-check-circle right mesh-page" aria-hidden="true" [ngClass]="inputData.menu_check?.mesh_size ? 'actived' : ''"></i>
          </a>
        </li>
        <li class='sub-menu' [ngClass]="init_load == 'simulation' ? 'active' : ''" (click)="onClickSimulation()" value="6">
          <a class="dropright" rel="simulator">
            <img src="./assets/image/06 simulation runs.png">
            <b>Simulation Run</b>
            <i class="fa fa-check-circle right simulation-page" aria-hidden="true" [ngClass]="inputData.menu_check?.running ? 'actived' : ''"></i>
          </a>
        </li>
        <!-- <li class="sub-menu" (click)="onClickDropDown('result')">  -->
        <li class="sub-menu menu-result" dir="{{ showResult ? inputData.menu_check.result : inputData.menu_check.running }}" value="7">
          <a>
            <img src="./assets/image/07 result.png">
            <b>Results</b>
            <div class='fa fa-angle-down right arrown'></div>
          </a>
          <ul class="sub-item" [ngStyle]="{'display': init_load == 'result' || init_load == 'displacement' || init_load == 'stress' || init_load == 'sif' || init_load == 'cyclelife' || init_load == 'fad' ? 'block' : 'none' }">
            <li [ngClass]="init_load == 'displacement' ? 'active' : ''" (click)="onClickResult('displacement')">
              <img class="symbol-l" src="./assets/image/L.png">
              <a>Displacement</a>
            </li>
            <li [ngClass]="init_load == 'stress' ? 'active' : ''" (click)="onClickResult('stress')">
              <img class="symbol-l" src="./assets/image/L.png">
              <a>Stress</a>
            </li>
            <li class="sub-menu-2 {{ init_load }}" [ngClass]="init_load == 'sif' || init_load == 'cyclelife' || init_load == 'fad' ? 'active' : ''">
              <img class="symbol-l expand" src="./assets/image/expand 2.png">
              <img class="symbol-l hide" src="./assets/image/hide 2.png">
              <a>Fracture</a>
            </li>
          </ul>
          <ul class="sub-item-2 pt-0 px-5" [ngClass]="init_load == 'sif' || init_load == 'cyclelife' || init_load == 'fad' ? 'active' : ''">
            <li [ngClass]="init_load == 'sif' ? 'active' : ''" (click)="onClickResult('sif')">
              <img class="symbol-l" src="./assets/image/L.png">
              <a>SIF</a>
            </li>
            <li [ngClass]="init_load == 'cyclelife' ? 'active' : ''" (click)="onClickResult('cyclelife')">
              <img class="symbol-l" src="./assets/image/L.png">
              <a>Cycle Life</a>
            </li>
            <li [ngClass]="init_load == 'fad' ? 'active' : ''" (click)="onClickResult('fad')">
              <img class="symbol-l" src="./assets/image/L.png">
              <a>FAD</a>
            </li>
          </ul>
        </li>
        <li class='sub-menu' [ngClass]="init_load == 'report' ? 'active' : ''" (click)="onClickReport()" value="8">
          <a class="dropright" rel="report">
            <img src="./assets/image/report.png" height="20px">
            <b>Report</b>
          </a>
        </li>
      </ul>
    </nav>
    <div class="col-md-2 fixed-bottom resource-page">
      Server status: 
      CPU: {{ resource.solver_cpu }} | 
      RAM: {{ resource.solver_ram }}
    </div>
  </div>
  <div class="col-md-10">
    <!-- Form content -->
    <router-outlet></router-outlet>
  </div>
</div>