/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Udf } from '../models/udf';
import { TypeAny } from '../models/type-any';
import { Template } from '../models/template';
import { UDFCheck } from '../models/udfcheck';


@Injectable()
export class UdfsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param udf_type - undefined
   * @param page - undefined
   * @param limit - undefined
   * @param admin - undefined
   */
  getListUdfResponse(params: UdfsService.GetListUdfParams): Observable<HttpResponse<Udf[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.udfType != null) __params = __params.set("udf_type", params.udfType.toString());
    if (params.page != null) __params = __params.set("page", params.page.toString());
    if (params.limit != null) __params = __params.set("limit", params.limit.toString());
    if (params.admin != null) __params = __params.set("admin", params.admin.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/udf`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Udf[] = null;
        _body = _resp.body as Udf[]
        return _resp.clone({body: _body}) as HttpResponse<Udf[]>;
      })
    );
  }

  /**
   * @param udf_type - undefined
   * @param page - undefined
   * @param limit - undefined
   * @param admin - undefined
   */
  getListUdf(params: UdfsService.GetListUdfParams): Observable<Udf[]> {
    return this.getListUdfResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  createUdfResponse(body?: TypeAny): Observable<HttpResponse<Udf>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/udf`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Udf = null;
        _body = _resp.body as Udf
        return _resp.clone({body: _body}) as HttpResponse<Udf>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  createUdf(body?: TypeAny): Observable<Udf> {
    return this.createUdfResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param id - undefined
   */
  getUdfByIdResponse(id: string): Observable<HttpResponse<Udf>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/udf/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Udf = null;
        _body = _resp.body as Udf
        return _resp.clone({body: _body}) as HttpResponse<Udf>;
      })
    );
  }

  /**
   * @param id - undefined
   */
  getUdfById(id: string): Observable<Udf> {
    return this.getUdfByIdResponse(id).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param id - undefined
   * @param body - save update
   */
  updateUdfResponse(params: UdfsService.UpdateUdfParams): Observable<HttpResponse<Udf>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/udf/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Udf = null;
        _body = _resp.body as Udf
        return _resp.clone({body: _body}) as HttpResponse<Udf>;
      })
    );
  }

  /**
   * @param id - undefined
   * @param body - save update
   */
  updateUdf(params: UdfsService.UpdateUdfParams): Observable<Udf> {
    return this.updateUdfResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param id - undefined
   */
  deleteUdfResponse(id: string): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/udf/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param id - undefined
   */
  deleteUdf(id: string): Observable<TypeAny> {
    return this.deleteUdfResponse(id).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - download template
   */
  downloadTemplateResponse(body?: Template): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/udf/template/download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param body - download template
   */
  downloadTemplate(body?: Template): Observable<TypeAny> {
    return this.downloadTemplateResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - check udf
   */
  checkUDFResponse(body?: UDFCheck): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/udf/function/check`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param body - check udf
   */
  checkUDF(body?: UDFCheck): Observable<TypeAny> {
    return this.checkUDFResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param functionName - undefined
   */
  getUdfByFunctionNameResponse(functionName: string): Observable<HttpResponse<Udf>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/udf/function-name/${functionName}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Udf = null;
        _body = _resp.body as Udf
        return _resp.clone({body: _body}) as HttpResponse<Udf>;
      })
    );
  }

  /**
   * @param functionName - undefined
   */
  getUdfByFunctionName(functionName: string): Observable<Udf> {
    return this.getUdfByFunctionNameResponse(functionName).pipe(
      map(_r => _r.body)
    );
  }}

export module UdfsService {
  export interface GetListUdfParams {
    udfType?: string;
    page?: number;
    limit?: number;
    admin?: number;
  }
  export interface UpdateUdfParams {
    id: string;
    body?: TypeAny;
  }
}
