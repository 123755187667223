/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Document } from '../models/document';
import { Project } from '../models/project';
import { TypeAny } from '../models/type-any';
import { Downloaddoc } from '../models/downloaddoc';


@Injectable()
export class DocumentationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   */
  getDocsResponse(): Observable<HttpResponse<Document[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/documentations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Document[] = null;
        _body = _resp.body as Document[]
        return _resp.clone({body: _body}) as HttpResponse<Document[]>;
      })
    );
  }

  /**
   */
  getDocs(): Observable<Document[]> {
    return this.getDocsResponse().pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   */
  getDocByIdResponse(projectId: string): Observable<HttpResponse<Project>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/documentations/${projectId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Project = null;
        _body = _resp.body as Project
        return _resp.clone({body: _body}) as HttpResponse<Project>;
      })
    );
  }

  /**
   * @param projectId - undefined
   */
  getDocById(projectId: string): Observable<Project> {
    return this.getDocByIdResponse(projectId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   * @param body - undefined
   */
  copyDocResponse(params: DocumentationsService.CopyDocParams): Observable<HttpResponse<Project>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/documentations/${params.projectId}/copy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Project = null;
        _body = _resp.body as Project
        return _resp.clone({body: _body}) as HttpResponse<Project>;
      })
    );
  }

  /**
   * @param projectId - undefined
   * @param body - undefined
   */
  copyDoc(params: DocumentationsService.CopyDocParams): Observable<Project> {
    return this.copyDocResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  downloadDocResponse(body?: Downloaddoc): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/documentations/download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  downloadDoc(body?: Downloaddoc): Observable<TypeAny> {
    return this.downloadDocResponse(body).pipe(
      map(_r => _r.body)
    );
  }}

export module DocumentationsService {
  export interface CopyDocParams {
    projectId: string;
    body?: Project;
  }
}
