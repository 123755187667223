import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../api/services';
import { ForgotPass } from '../../api/models';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {

  public forgotPass: ForgotPass;
  public contact: string = environment.contact;
  public phone: string = environment.phone;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
  ) { 
    this.forgotPass = new ForgotPass();
  }

  ngOnInit() {
    this.forgotPass.resetToken = this.route.snapshot.paramMap.get('token');
    this.forgotPass.email = this.route.snapshot.paramMap.get('email');
  }

  onReset() {
    if (!this.forgotPass.password) {
      swal('Warning!', this.translate.instant('user.login.password.required'), 'warning');
      return;
    }
    if (!this.forgotPass.password_confirmation) {
      swal('Warning!', this.translate.instant('user.login.password.confirm.required'), 'warning');
      return;
    }
    if (this.forgotPass.password != this.forgotPass.password_confirmation) {
      swal('Warning!', this.translate.instant('user.login.password.match'), 'warning');
      return;
    }
    this.spinner.show();
    this.authService.resetPass(this.forgotPass).subscribe(
      res => {
        this.spinner.hide();
        this.router.navigate(['/auth/signin']);
      },
      err => {
        this.spinner.hide();
        swal({ type: 'error', title: 'Error!', text: err.error.error });
      }
    );
  }

}