/* tslint:disable */

/**
 */
export class Face {
    id?: number;
    project_id?: number;
    name?: string;
    type_group?: string;
}
