/* tslint:disable */

/**
 */
export class ForgotPass {
    resetToken?: string;
    email?: string;
    password?: string;
    password_confirmation?: string;
}
