/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Folder } from '../models/folder';
import { TypeAny } from '../models/type-any';


@Injectable()
export class FoldersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param folder_name - undefined
   * @param folder_id - undefined
   */
  getFoldersResponse(params: FoldersService.GetFoldersParams): Observable<HttpResponse<Folder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.folderName != null) __params = __params.set("folder_name", params.folderName.toString());
    if (params.folderId != null) __params = __params.set("folder_id", params.folderId.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/folders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Folder = null;
        _body = _resp.body as Folder
        return _resp.clone({body: _body}) as HttpResponse<Folder>;
      })
    );
  }

  /**
   * @param folder_name - undefined
   * @param folder_id - undefined
   */
  getFolders(params: FoldersService.GetFoldersParams): Observable<Folder> {
    return this.getFoldersResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  createFolderResponse(body?: Folder): Observable<HttpResponse<Folder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/folders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Folder = null;
        _body = _resp.body as Folder
        return _resp.clone({body: _body}) as HttpResponse<Folder>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  createFolder(body?: Folder): Observable<Folder> {
    return this.createFolderResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param folderId - undefined
   * @param body - undefined
   */
  updateFolderResponse(params: FoldersService.UpdateFolderParams): Observable<HttpResponse<Folder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/folders/${params.folderId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Folder = null;
        _body = _resp.body as Folder
        return _resp.clone({body: _body}) as HttpResponse<Folder>;
      })
    );
  }

  /**
   * @param folderId - undefined
   * @param body - undefined
   */
  updateFolder(params: FoldersService.UpdateFolderParams): Observable<Folder> {
    return this.updateFolderResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param folderId - undefined
   */
  deleteFolderResponse(folderId: number): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/folders/${folderId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param folderId - undefined
   */
  deleteFolder(folderId: number): Observable<TypeAny> {
    return this.deleteFolderResponse(folderId).pipe(
      map(_r => _r.body)
    );
  }}

export module FoldersService {
  export interface GetFoldersParams {
    folderName?: string;
    folderId?: number;
  }
  export interface UpdateFolderParams {
    folderId: number;
    body?: Folder;
  }
}
