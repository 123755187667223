/* tslint:disable */

/**
 */
export class Resource {
    solver_cpu?: string;
    solver_ram?: string;
    total_cpu?: number;
    used_cpu?: number;
}
