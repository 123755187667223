<header class="py-1 pl-3 border-bottom">
  <div class="container-fluid d-grid gap-3 align-items-center" style="grid-template-columns: 6fr 2fr;">
    <div class="dropdown">
      <a class="d-flex align-items-center col-lg-4 mb-2 mb-lg-0 link-dark text-decoration-none menu-project">
        <img src="assets/image/logo.png" [routerLink]="['/manager/dashboard']">
        <h3 class="p-2 mb-0 mr-5" [routerLink]="['/manager/project', projectid]"><b><pre class="mb-0">{{ projectname }}</pre></b></h3>
        <img class="rounded-circle m-1 box-shadow" src="assets/image/project copy.png" (click)="showCopy()">
      </a>
    </div>
    <div class="d-flex align-items-center">
      <div class="w-100 me-3" role="search">
      </div>
      <div class="flex-shrink-0 dropdown">
        <a href="#" class="d-flex align-items-center link-dark text-decoration-none " data-toggle="dropdown"
          aria-expanded="false">
          <h6 class="p-2 mb-0"><b class="user-name">{{ security.name }}</b></h6>
          <img src="{{ avatar }}" alt="mdo" width="32" height="32" class="rounded-circle img-logo">
        </a>
        <ul class="dropdown-menu text-small shadow">
          <li><a class="dropdown-item" [routerLink]="['/manager/dashboard']">Dashboard</a></li>
          <li><a class="dropdown-item" [routerLink]="['/manager/profile']">View profile</a></li>
          <li *ngIf="security.role == 'admin'"><a target="_blank" class="dropdown-item" [href]="domainBO">Admin</a></li>
          <li><a class="dropdown-item" [routerLink]="['/manager/document']">Documentations</a></li>
          <li><a class="dropdown-item cursor-pointer" (click)="showAbout()">About</a></li>
          <li><a class="dropdown-item" [routerLink]="['/auth/signout']">Log out</a></li>
        </ul>
      </div>
    </div>
  </div>
</header>
<div bsModal #copyProjectModal="bs-modal" class="modal fade header-project" tabindex="-1" role="dialog"
  aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header ">
        <h5 class="modal-title text-center text-uppercase">Copy project</h5>
        <button type="button" class="btn-close close" data-dismiss="modal" aria-label="Close"
          (click)="copyProjectModal.hide()"></button>
      </div>
      <div class="modal-body">
        <div class="form-group mt-4">
          <label>Project Title</label>
          <input type="text" class="form-control" [(ngModel)]="projectForm.title">
        </div>
        <div class="form-group mt-4">
          <label>Folder</label>
          <select class="form-select" [(ngModel)]="projectForm.parent_id">
            <option [value]="0">Dashboard</option>
            <option *ngFor="let item of folders" [value]="item.id">{{ item.name }}</option>
          </select>
      </div>
        <div class="form-group mt-4">
          <label>Description</label>
          <textarea class="form-control" rows="3" [(ngModel)]="projectForm.description"></textarea>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-cancel" data-dismiss="modal" aria-label="Close"
          (click)="copyProjectModal.hide()">Cancel</button>
        <button type="button" class="btn btn-primary btn-action" (click)="copyProject()">Copy</button>
      </div>
    </div>
  </div>
</div>
<div bsModal #aboutModal="bs-modal" class="modal fade" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header ">
        <h5 class="modal-title text-center text-uppercase">About</h5>
        <button type="button" class="btn-close close" data-dismiss="modal" aria-label="Close"
          (click)="aboutModal.hide()"></button>
      </div>
      <div class="modal-body about">
        <div class="form-group text-center mb-1">
          <img src="{{ about.logo }}" alt="logo" class="">
        </div>
        <div class="form-group text-center mb-4">
          Version <span>{{ about.version }}</span>
        </div>
        <div class="form-group text-center">
          Product of <span>{{ about.product_of }}</span>
        </div>
        <div class="form-group text-center">
          Developed by <span>{{ about.developed_by }}</span>
        </div>
        <div class="form-group text-center">
          {{ about.address }}
        </div>
        <div class="form-group text-center mt-2">
          Email: <a href="mailto:{{ about.email }}">{{ about.email }}</a>
        </div>
        <div class="form-group text-center">
          Website: <a href="{{ about.website }}" target="_blank">{{ about.website }}</a>
        </div>
        <div class="form-group text-center mt-2">
          <a class="release-note" (click)="viewDoc(about.release_notes)">Release notes</a>
        </div>
      </div>
    </div>
  </div>
</div>