/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { TypeAny } from '../models/type-any';
import { Report } from '../models/report';
import { Resource } from '../models/resource';
import { Project } from '../models/project';


@Injectable()
export class WorkbenchsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param version - undefined
   */
  getDefaultDataResponse(version?: string): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (version != null) __params = __params.set("version", version.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/default-data`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param version - undefined
   */
  getDefaultData(version?: string): Observable<TypeAny> {
    return this.getDefaultDataResponse(version).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   */
  downloadResultResponse(projectId: string): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/workbench/${projectId}/download-result`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param projectId - undefined
   */
  downloadResult(projectId: string): Observable<TypeAny> {
    return this.downloadResultResponse(projectId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   */
  getReportResponse(projectId: string): Observable<HttpResponse<Report>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/workbench/report/${projectId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Report = null;
        _body = _resp.body as Report
        return _resp.clone({body: _body}) as HttpResponse<Report>;
      })
    );
  }

  /**
   * @param projectId - undefined
   */
  getReport(projectId: string): Observable<Report> {
    return this.getReportResponse(projectId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   * @param body - generate report
   */
  updateReportResponse(params: WorkbenchsService.UpdateReportParams): Observable<HttpResponse<Report>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/workbench/report/${params.projectId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Report = null;
        _body = _resp.body as Report
        return _resp.clone({body: _body}) as HttpResponse<Report>;
      })
    );
  }

  /**
   * @param projectId - undefined
   * @param body - generate report
   */
  updateReport(params: WorkbenchsService.UpdateReportParams): Observable<Report> {
    return this.updateReportResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   * @param body - save capture
   */
  saveCaptureResponse(params: WorkbenchsService.SaveCaptureParams): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/workbench/capture/image/${params.projectId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param projectId - undefined
   * @param body - save capture
   */
  saveCapture(params: WorkbenchsService.SaveCaptureParams): Observable<TypeAny> {
    return this.saveCaptureResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   */
  downloadCSVResponse(projectId: string): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/workbench/${projectId}/download-csv`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param projectId - undefined
   */
  downloadCSV(projectId: string): Observable<TypeAny> {
    return this.downloadCSVResponse(projectId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   * @param body - delete capture
   */
  deleteCaptureResponse(params: WorkbenchsService.DeleteCaptureParams): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    __body = params.body;
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/workbench/${params.projectId}/image`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param projectId - undefined
   * @param body - delete capture
   */
  deleteCapture(params: WorkbenchsService.DeleteCaptureParams): Observable<TypeAny> {
    return this.deleteCaptureResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   * @param imageId - undefined
   */
  checkedCaptureResponse(params: WorkbenchsService.CheckedCaptureParams): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/workbench/${params.projectId}/image/checked/${params.imageId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param projectId - undefined
   * @param imageId - undefined
   */
  checkedCapture(params: WorkbenchsService.CheckedCaptureParams): Observable<TypeAny> {
    return this.checkedCaptureResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param type - undefined
   * @param status - undefined
   * @param projectId - undefined
   */
  checkedAllResponse(params: WorkbenchsService.CheckedAllParams): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/workbench/${params.projectId}/image/checkedall/${params.type}/${params.status}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param type - undefined
   * @param status - undefined
   * @param projectId - undefined
   */
  checkedAll(params: WorkbenchsService.CheckedAllParams): Observable<TypeAny> {
    return this.checkedAllResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param type - undefined
   * @param projectId - undefined
   */
  deleteAllResponse(params: WorkbenchsService.DeleteAllParams): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/workbench/${params.projectId}/image/deleteall/${params.type}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param type - undefined
   * @param projectId - undefined
   */
  deleteAll(params: WorkbenchsService.DeleteAllParams): Observable<TypeAny> {
    return this.deleteAllResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   */
  getResourceResponse(): Observable<HttpResponse<Resource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/resources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resource = null;
        _body = _resp.body as Resource
        return _resp.clone({body: _body}) as HttpResponse<Resource>;
      })
    );
  }

  /**
   */
  getResource(): Observable<Resource> {
    return this.getResourceResponse().pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param type - undefined
   * @param projectId - undefined
   */
  getVideosResponse(params: WorkbenchsService.GetVideosParams): Observable<HttpResponse<Project[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/workbench/${params.projectId}/video/${params.type}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Project[] = null;
        _body = _resp.body as Project[]
        return _resp.clone({body: _body}) as HttpResponse<Project[]>;
      })
    );
  }

  /**
   * @param type - undefined
   * @param projectId - undefined
   */
  getVideos(params: WorkbenchsService.GetVideosParams): Observable<Project[]> {
    return this.getVideosResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param type - undefined
   * @param projectId - undefined
   */
  generateVideoResponse(params: WorkbenchsService.GenerateVideoParams): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/workbench/${params.projectId}/video/${params.type}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param type - undefined
   * @param projectId - undefined
   */
  generateVideo(params: WorkbenchsService.GenerateVideoParams): Observable<TypeAny> {
    return this.generateVideoResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  uploadCSVResponse(body?: TypeAny): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/workbench/peaking/csv`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  uploadCSV(body?: TypeAny): Observable<TypeAny> {
    return this.uploadCSVResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   * @param body - undefined
   */
  uploadWordResponse(params: WorkbenchsService.UploadWordParams): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/workbench/report/${params.projectId}/upload/word`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param projectId - undefined
   * @param body - undefined
   */
  uploadWord(params: WorkbenchsService.UploadWordParams): Observable<TypeAny> {
    return this.uploadWordResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   */
  downloadPDFResponse(projectId: string): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/workbench/report/${projectId}/pdf/download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param projectId - undefined
   */
  downloadPDF(projectId: string): Observable<TypeAny> {
    return this.downloadPDFResponse(projectId).pipe(
      map(_r => _r.body)
    );
  }}

export module WorkbenchsService {
  export interface UpdateReportParams {
    projectId: string;
    body?: Report;
  }
  export interface SaveCaptureParams {
    projectId: string;
    body?: TypeAny;
  }
  export interface DeleteCaptureParams {
    projectId: string;
    body?: TypeAny;
  }
  export interface CheckedCaptureParams {
    projectId: string;
    imageId: number;
  }
  export interface CheckedAllParams {
    type: string;
    status: number;
    projectId: string;
  }
  export interface DeleteAllParams {
    type: string;
    projectId: string;
  }
  export interface GetVideosParams {
    type: string;
    projectId: string;
  }
  export interface GenerateVideoParams {
    type: string;
    projectId: string;
  }
  export interface UploadWordParams {
    projectId: string;
    body?: TypeAny;
  }
}
