import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  //dataRefresher: any;
  public cookie_key = '@$qaz@23QSt558BmWiOt'
  tokenCrypto: string = "01B34q67a91W34f6";

  constructor(
    private translate: TranslateService,
    private router: Router
  ) {
    
  }

  // Return false if field input is null or empty;
  public isNullOrEmpty(input) {
    if (input == undefined) {
      return true;
    } else if (input == '') {
      return true;
    }
    return false;
  }

  public nameInvalid(input) {
    let regex = /^[a-zA-Z0-9_]*$/;
    if (input.match(regex)) {
      return true;
    }
    return false;
  }

  public nameUserInvalid(input) {
    let regex = /^[a-zA-Z0-9 _]*$/;
    if (input.match(regex)) {
      return true;
    }
    return false;
  }

  public isEmailInvalid(input) {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (input.match(regex)) {
      return false;
    }
    return true;
  }

  public isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  public isScientific(n) {
    let decimal = (n).toLocaleString('fullwide', {useGrouping:false});
    return !isNaN(parseFloat(decimal)) && isFinite(decimal);
  }

  public isNotNumber(input) {
    return isNaN(input);
  }

  setCookie(name: string, value: string, expireDays: number, path: string = '') {
    value = this.encrypt(value);
    let d:Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires:string = `expires=${d.toUTCString()}`;
    let cpath:string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) == 0) {
            return this.decrypt(c.substring(cookieName.length, c.length));
        }
    }
    return '';
  }

  deleteCookie(name: string) {
      this.setCookie(name, '', -1);
  }

  public encrypt(req: any) {
    if (req) {
      let _key = CryptoJS.enc.Utf8.parse(this.tokenCrypto);
      let _iv = CryptoJS.enc.Utf8.parse(this.tokenCrypto);
      let data = req;
      if (typeof req === 'object') data = JSON.stringify(req);
      let encrypted = CryptoJS.AES.encrypt(
          data, _key, {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        });
      return encrypted.toString();
    }
  }

  public decrypt(req: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenCrypto);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenCrypto);
    let decrypted = CryptoJS.AES.decrypt(
        req, _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }

  setUser(user: any) {
    let value = this.encrypt(user);
    localStorage.setItem('fatcal-user', value);
  }

  getUser() {
    let value = localStorage.getItem('fatcal-user');
    if (value) {
      return JSON.parse(this.decrypt(value));
    } else {
      localStorage.clear();
      this.router.navigate(['/auth/signin']);
    }
  }

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  setDefaultData(data: any) {
    localStorage.setItem('default-data', JSON.stringify(data));
  }

  getDefaultData() {
    return JSON.parse(localStorage.getItem('default-data'));
  }

  removeDefaultData() {
    localStorage.removeItem('default-data');
  }

  setInputData(data: any) {
    localStorage.setItem('input-data', JSON.stringify(data));
  }

  getInputData() {
    return JSON.parse(localStorage.getItem('input-data'));
  }

  removeInputData() {
    localStorage.removeItem('input-data');
  }

  getResultStatus() {
    return JSON.parse(localStorage.getItem('result-status'));
  }

  setResultStatus(data: any) {
    localStorage.setItem('result-status', JSON.stringify(data));
  }

  kFormatter(num) {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000)) + 'k' : Math.sign(num)*Math.abs(num)
  }

  showAlertSuccess(title = null) {
    swal({
      position: 'top-end',
      title: title ? title + ' &nbsp;&nbsp;<i class="fa fa-check" aria-hidden="true"></i>' : this.translate.instant('common.update.success') + ' &nbsp;&nbsp;<i class="fa fa-check" aria-hidden="true"></i>',
      showConfirmButton: false,
      backdrop: false,
      customClass: 'swal-custom',
      timer: environment.timer
    });
  }

  roundCustom(num, limit) {
    let a = (num).toString();
    return Number(a.substring(0, a.indexOf('.') + 7)).toFixed(limit);
  }

}