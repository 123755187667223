/* tslint:disable */
import { User } from './user';

/**
 */
export class Login {
    refresh_token?: string;
    access_token?: string;
    user?: User;
}
