/* tslint:disable */

/**
 */
export class Report {
    id?: number;
    company_name?: string;
    name?: string;
    function?: string;
    contact?: string;
    company_name_study?: string;
    name_study?: string;
    function_study?: string;
    contact_study?: string;
    comment?: string;
    image?: string;
    logo?: string;
    customer_logo?: string;
    sif_image?: string;
    cycle_image?: string;
}
