<form class="form-signin" (ngSubmit)="onLogin()">
    <img class="mb-5 logo-login" src="assets/image/logo-big.png">
    <div class="input-group">
        <div class="input-group-prepend place-self-center">
            <span class="input-group-text"><i class="fa fa-user"
                    aria-hidden="true"></i></span>
        </div>
        <input type="text" class="form-control" [ngClass]="emailInvalid ? 'is-invalid' : ''" id="validationTooltipUsername" placeholder="Username"
            aria-describedby="validationTooltipUsernamePrepend" [(ngModel)]="user.email"
            [ngModelOptions]="{standalone: true}" required>

    </div>
    <div class="input-group">
        <div class="input-group-prepend place-self-center">
            <span class="input-group-text"><i class="fa fa-lock"
                    aria-hidden="true"></i></span>
        </div>
        <input type="password" class="form-control" id="validationTooltipPasswordPrepend" placeholder="Password"
            aria-describedby="validationTooltipPasswordPrepend" [(ngModel)]="user.password"
            [ngModelOptions]="{standalone: true}" required autocomplete="off">
        <i class="fa fa-eye show-password" aria-hidden="true"></i>
        <div class="invalid-tooltip">
            Please choose a unique and valid password.
        </div>
    </div>
    <button class="btn btn-lg btn-primary btn-block mt-4 px-5 btn-action" type="submit">Login</button>
    <p class="mt-3 mb-3 text-muted"><a [routerLink]="['/auth/forgot']">Forgot password</a></p>
</form>
<!-- <div class="infor-login">
    <p><i class="fa fa-envelope" aria-hidden="true"></i>{{ contact }}</p>
    <p><i class="fa fa-volume-control-phone" aria-hidden="true"></i>{{ phone }}</p>
</div> -->