/* tslint:disable */

/**
 */
export class Units {
    value_type?: string;
    symbol?: string;
    A?: number;
    B?: number;
}
