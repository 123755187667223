import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WorkbenchsService, ProjectsService, PermissionsService } from '../../api/services';
import { Project, Resource, Face } from '../../api/models';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../shared/common.service';
import * as DefaultData from '../../api/exts/default_data.json';
import * as InputData from '../../api/exts/paraInput_adjust.json';
import { environment } from '../../../environments/environment';
//declare function unCheckMenu(page: any): any;
declare function disabledMenu(page: any);
declare function warningMenu(page: any, color: any);
declare function disabledMenuPlus(page: any);
@Component({
  selector: 'app-workbench-layout',
  templateUrl: './workbench-layout.component.html',
  styleUrls: ['./workbench-layout.component.css']
})
export class WorkbenchLayoutComponent implements OnInit {

  public projectid: any = 0;
  public defaultData: any = DefaultData;
  public inputData: any = InputData;
  public project: Project;
  public inputParam: Project;
  public init_load = '';
  public resource: Resource;
  public faceid: any = 0;
  public meshStatus: any = '';
  public runningStatus: any = '';
  public security: any;
  public faces: Face[] = [];
  public showResult: any = 0;
  public expertAccess: any = false;
  
  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private workbenchsService: WorkbenchsService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private projectsService: ProjectsService,
    private ngZone: NgZone,
    private permissionsService: PermissionsService
  ) {
    this.project = new Project();
    this.inputParam = new Project();
    this.resource = new Resource();
  }

  ngOnInit() {
    let url = this.router.url.split('/');
    this.projectid = url[url.length - 1];
    this.init_load = url[url.length - 2];
    this.faceid = url[url.length - 3];
    this.security = this.commonService.getUser();
    let resultStatus = this.commonService.getResultStatus();
    //console.log(resultStatus)
    if (resultStatus) {
      if (!resultStatus['mesh']) {
        this.meshStatus = '';
      } else if (resultStatus['mesh'] == 1) {
        this.meshStatus = 'green';
      } else if (resultStatus['mesh'] == 2) {
        this.meshStatus = 'red';
      }
      if (!resultStatus['running']) {
        this.runningStatus = '';
      } else if (resultStatus['running'] == 1) {
        this.runningStatus = 'green';
      } else if (resultStatus['running'] == 2) {
        this.runningStatus = 'red';
      } 
    }
    warningMenu('.mesh-page', this.meshStatus);
    warningMenu('.simulation-page', this.runningStatus);
    let defaultData = this.commonService.getDefaultData();
    if (defaultData) {
      //console.log('co defaultData', defaultData);
      this.defaultData = defaultData;
    }
    this.inputData = this.commonService.getInputData();
    if (this.inputData) {
      this.spinner.show();
      this.getProjectById();
    } else {
      this.getDefaultData();
    }
    window['globalReference'] = { component: this, zone: this.ngZone, loadAlert: (val: any) => this.showAlert(val)};
    this.getResource();
    this.getFaces();
    this.checkExpertRole();
  }

  checkExpertRole() {
    this.permissionsService.getPermissions().subscribe(
      res => {
        if (res['data'].indexOf('import_mesh') > -1) {
          this.expertAccess = true;
        }
      }
    );
  }

  getResource() {
    this.workbenchsService.getResource().subscribe(
      res => {
        this.resource = res['data'];
        setTimeout(()=>{
          this.getResource();
        }, environment.limit_set_timeout);//300000
      }
    );
  }

  showAlert(val) {
    if (val == 'boundary') {
      swal('Warning!', this.translate.instant('workbench.boundary.required'), 'warning');
      return;
    } else if (val == 'result') {
      swal('Warning!', this.translate.instant('workbench.result.required'), 'warning');
      return;
    }
  }  

  getProjectById() {
    let params: ProjectsService.GetProjectByIdParams = {
      projectId: this.projectid
    };
    this.projectsService.getProjectById(params).subscribe(
      res => {
        this.project = res['data'];
        //console.log('this.project',res['data']);
        this.inputData.project_name = this.project.title;
        if (this.project.params) {
          this.inputData = this.project.params;
          if (this.init_load == 'geometry') {
            if (this.project.status != 1 && this.project.status != 3) {
              if (this.inputData.menu_check.geometry.plate){
                this.init_load = 'plate';
                this.router.navigate(['/workbench/geometry/plate', this.projectid]);
              }
              else if (this.inputData.menu_check.geometry.cylinder){
                this.init_load = 'cylinder';
                this.router.navigate(['/workbench/geometry/cylinder', this.projectid]);
              }
              else if (this.inputData.menu_check.geometry.vessel){
                this.init_load = 'vessel';
                this.router.navigate(['/workbench/geometry/vessel', this.projectid]);
              }
              else if (this.inputData.menu_check.geometry.expert_geo){
                this.init_load = 'expert';
                this.router.navigate(['/workbench/geometry/expert', this.projectid]);
              }
            } else {
              if (this.project.status == 1) {
                disabledMenu('5');
                this.init_load = 'mesh';
                this.router.navigate(['/workbench/mesh/', this.projectid]);
              }
              if (this.project.status == 3) {
                //disabledMenu('6');
                if (this.inputData.geometry.shape == 'cylinder' || this.inputData.geometry.shape == 'vessel' || this.inputData.geometry.shape == 'expert_geo') {
                  if (this.inputData.geometry[this.inputData.geometry.shape].numerics[this.inputData.geometry[this.inputData.geometry.shape].numerics.method].crack_ratio_properties == 'adaptive' 
                  || this.inputData.geometry[this.inputData.geometry.shape].numerics[this.inputData.geometry[this.inputData.geometry.shape].numerics.method].crack_ratio_properties == 'mode_1') {
                    disabledMenuPlus('6');
                    this.showResult = 1;
                  } else {
                    disabledMenu('6');
                    this.showResult = 0;
                  }
                } else {
                  disabledMenu('6');
                  this.showResult = 0;
                }
                //disabledMenuPlus('6');
                this.init_load = 'simulation';
                this.router.navigate(['/workbench/simulation/', this.projectid]);
              }
            }
          } else {
            if (this.inputData.geometry.shape == 'cylinder' || this.inputData.geometry.shape == 'vessel' || this.inputData.geometry.shape == 'expert_geo') {
              if (this.inputData.geometry[this.inputData.geometry.shape].numerics[this.inputData.geometry[this.inputData.geometry.shape].numerics.method].crack_ratio_properties == 'adaptive' 
              || this.inputData.geometry[this.inputData.geometry.shape].numerics[this.inputData.geometry[this.inputData.geometry.shape].numerics.method].crack_ratio_properties == 'mode_1') {
                if (this.init_load == 'displacement' || this.init_load =='stress' || this.init_load =='sif' || this.init_load =='cyclelife' || this.init_load =='fad') {
                  if (this.project.status == 3) {
                    disabledMenuPlus('6');
                    this.showResult = 1;
                  }
                }
              }
            }
          }
          this.commonService.setInputData(this.inputData);
        }
        this.spinner.hide();
      }
    );
  }

  getFaces() {
    let params: ProjectsService.GetFacesParams = {
      projectId: this.projectid
    };
    this.projectsService.getFaces(params).subscribe(
      res => {
        //console.log(res)
        if (res['success']) {
          this.faces = res['data'];
        }
      }
    );
  }

  convertName(name: any) {
    if (name) {
      let lbl = name.split('_').join(' ');
      return lbl.charAt(0).toUpperCase() + lbl.slice(1);
      //let nameFace = lbl.charAt(0).toUpperCase() + lbl.slice(1);
      //return ( nameFace.length > 20 ) ? nameFace.slice(0, 20) + '...' : nameFace;
    }
  }

  checkAllFace() {
    const asArray = Object.entries(this.inputData.menu_check.boundary.expert_bcs);
    const filtered = asArray.filter(([key, value]) => value == false);
    if (filtered.length > 0) {
      return false;
    }
    return true;
  }

  getDefaultData() {
    this.spinner.show();
    this.workbenchsService.getDefaultData().subscribe(
      res => {
        //this.spinner.hide();
        this.defaultData = res['data']['data'];
        this.commonService.setDefaultData(this.defaultData);
        this.inputData = res['data']['param_input'];
        this.commonService.setInputData(this.inputData);
        this.getProjectById();
      }
    );
  }

  refreshData(menu, form = null) {
    let params: ProjectsService.GetProjectByIdParams = {
      projectId: this.projectid,
      //include: 'projectResult'
    };
    this.projectsService.getProjectById(params).subscribe(
      res => {
        this.project = res['data'];
        if (this.project.params) {
          this.inputData = this.project.params;
          if (menu == 'numeric') {
            if (this.inputData.menu_check.geometry.plate || this.inputData.menu_check.geometry.cylinder || this.inputData.menu_check.geometry.vessel || this.inputData.menu_check.geometry.expert_geo) {
              this.init_load = 'numeric';
              this.router.navigate(['/workbench/numeric/', this.projectid]);
            } else {
              swal('Warning!', this.translate.instant('workbench.geometry.required'), 'warning');
              return;
            }
          }
          if (menu == 'material') {
            if (this.inputData.menu_check.numeric.plate || this.inputData.menu_check.numeric.cylinder || this.inputData.menu_check.numeric.vessel || this.inputData.menu_check.numeric.expert_geo) {
              this.init_load = 'material';
              this.router.navigate(['/workbench/material/', this.projectid]);
            } else {
              swal('Warning!', this.translate.instant('workbench.material.required'), 'warning');
              return;
            }
          }
          if (menu == 'boundary') {
            if (this.inputData.menu_check.material) {
              this.init_load = form;
              if (form)
                this.router.navigate(['/workbench/boundary/'+form, this.projectid]);
            } else {
              swal('Warning!', this.translate.instant('workbench.boundary.required'), 'warning');
              return;
            }
          }
          if (menu == 'mesh') {
            if (!this.inputData.menu_check.geometry.expert_geo){
              if (this.inputData.menu_check.boundary.pressure && this.inputData.menu_check.boundary.symmetry_plane) {
                this.init_load = 'mesh';
                this.router.navigate(['/workbench/mesh/', this.projectid]);
              } else {
                swal('Warning!', this.translate.instant('workbench.mesh.required'), 'warning');
                return;
              }
            } else {
              this.init_load = 'mesh';
              this.router.navigate(['/workbench/mesh/', this.projectid]);
            }
          }
          if (menu == 'simulation') {
            if (!this.inputData.menu_check.geometry.expert_geo){
              if (this.inputData.menu_check.boundary.pressure && this.inputData.menu_check.boundary.symmetry_plane) {
                this.init_load = 'simulation';
                this.router.navigate(['/workbench/simulation/', this.projectid]);
              } else {
                swal('Warning!', this.translate.instant('workbench.mesh.required'), 'warning');
                return;
              }
            } else {
              if (this.checkAllFace()) {
                this.init_load = 'simulation';
                this.router.navigate(['/workbench/simulation/', this.projectid]);
              } else {
                swal('Warning!', this.translate.instant('workbench.mesh.required'), 'warning');
                return;
              }
            }
          }
          if (menu == 'result') {
            if (this.inputData.geometry.shape == 'cylinder' || this.inputData.geometry.shape == 'vessel' || this.inputData.geometry.shape == 'expert_geo') {
              if (this.inputData.geometry[this.inputData.geometry.shape].numerics[this.inputData.geometry[this.inputData.geometry.shape].numerics.method].crack_ratio_properties == 'adaptive' 
              || this.inputData.geometry[this.inputData.geometry.shape].numerics[this.inputData.geometry[this.inputData.geometry.shape].numerics.method].crack_ratio_properties == 'mode_1') {
                if (this.inputData.menu_check.result) {
                  this.init_load = form;
                  if (form)
                    this.router.navigate(['/workbench/result/'+form, this.projectid]);
                } else {
                  if (this.project.status == 4) {
                    this.init_load = form;
                    if (form)
                      this.router.navigate(['/workbench/result/'+form, this.projectid]);
                  } else {
                    swal('Warning!', this.translate.instant('workbench.result.required'), 'warning');
                    return;
                  }
                }
              } else {
                if (this.inputData.menu_check.running) {
                  this.init_load = form;
                  if (form)
                    this.router.navigate(['/workbench/result/'+form, this.projectid]);
                } else {
                  swal('Warning!', this.translate.instant('workbench.result.required'), 'warning');
                  return;
                }
              }
            } else {
              if (this.inputData.menu_check.running) {
                this.init_load = form;
                if (form)
                  this.router.navigate(['/workbench/result/'+form, this.projectid]);
              } else {
                swal('Warning!', this.translate.instant('workbench.result.required'), 'warning');
                return;
              }
            }
          }
          if (menu == 'report') {
            if (this.inputData.menu_check.running) {
              this.init_load = 'report';
              this.router.navigate(['/workbench/report/', this.projectid]);
            } else {
              swal('Warning!', this.translate.instant('workbench.result.required'), 'warning');
              return;
            }
          }
        }
      }
    );
  }

  onClickGeometry(form: any) {
    this.init_load = form;
    this.router.navigate(['/workbench/geometry/'+form, this.projectid]);
  }

  onClickNumeric() {
    this.inputData = this.commonService.getInputData();
    if (this.inputData.menu_check.geometry.plate || this.inputData.menu_check.geometry.cylinder || this.inputData.menu_check.geometry.vessel || this.inputData.menu_check.geometry.expert_geo) {
      this.init_load = 'numeric';
      this.router.navigate(['/workbench/numeric/', this.projectid]);
    } else {
      this.refreshData('numeric');
    }
  }

  onClickMaterial() {
    this.inputData = this.commonService.getInputData();
    if (this.inputData.menu_check.numeric.plate || this.inputData.menu_check.numeric.cylinder || this.inputData.menu_check.numeric.vessel || this.inputData.menu_check.numeric.expert_geo) {
      this.init_load = 'material';
      this.router.navigate(['/workbench/material/', this.projectid]);
    } else {
      this.refreshData('material');
    }
  }
 
  onClickBoundary(form: any) {
    this.inputData = this.commonService.getInputData();
    if (this.inputData.menu_check.material) {
      this.init_load = form;
      this.router.navigate(['/workbench/boundary/'+form, this.projectid]);
    } else {
      this.refreshData('boundary', form);
    }
  }

  onClickMesh() {
    this.inputData = this.commonService.getInputData();
    if (!this.inputData.menu_check.geometry.expert_geo){
      if (this.inputData.menu_check.boundary.pressure && this.inputData.menu_check.boundary.symmetry_plane) {
        this.init_load = 'mesh';
        this.router.navigate(['/workbench/mesh/', this.projectid]);
      } else {
        this.refreshData('mesh');
      }
    } else {
      this.init_load = 'mesh';
      this.router.navigate(['/workbench/mesh/', this.projectid]);
    }
  }

  onClickSimulation() {
    this.inputData = this.commonService.getInputData();
    if (!this.inputData.menu_check.geometry.expert_geo){
      if (this.inputData.menu_check.boundary.pressure && this.inputData.menu_check.boundary.symmetry_plane) {
        if (this.inputData.geometry[this.inputData.geometry.shape].numerics.method == 'analytics' || this.inputData.geometry[this.inputData.geometry.shape].numerics.method == 'analytics_Newman_1980') {
          this.init_load = 'simulation';
          this.router.navigate(['/workbench/simulation/', this.projectid]);
        } else if (this.inputData.menu_check.mesh_size) {
          this.init_load = 'simulation';
          this.router.navigate(['/workbench/simulation/', this.projectid]);
        } else {
          swal('Warning!', this.translate.instant('workbench.simulation.required'), 'warning');
          return;
        }
      } else {
        this.refreshData('simulation');
      }
    } else {
      if (this.checkAllFace()) {
        this.init_load = 'simulation';
        this.router.navigate(['/workbench/simulation/', this.projectid]);
      } else {
        swal('Warning!', this.translate.instant('workbench.mesh.required'), 'warning');
        return;
      }
    }
  }

  onClickResult(page: any) {
    this.inputData = this.commonService.getInputData();
    if (this.inputData.geometry.shape == 'cylinder' || this.inputData.geometry.shape == 'vessel' || this.inputData.geometry.shape == 'expert_geo') {
      if (this.inputData.geometry[this.inputData.geometry.shape].numerics[this.inputData.geometry[this.inputData.geometry.shape].numerics.method].crack_ratio_properties == 'adaptive' 
      || this.inputData.geometry[this.inputData.geometry.shape].numerics[this.inputData.geometry[this.inputData.geometry.shape].numerics.method].crack_ratio_properties == 'mode_1') {
        if (this.inputData.menu_check.result) {
          this.init_load = page;
          this.router.navigate(['/workbench/result/'+page, this.projectid]);
        } else {
          this.refreshData('result', page);
        }
      } else {
        if (this.inputData.menu_check.running) {
          this.init_load = page;
          this.router.navigate(['/workbench/result/'+page, this.projectid]);
        } else {
          this.refreshData('result', page);
        }
      }
    } else {
      if (this.inputData.menu_check.running) {
        this.init_load = page;
        this.router.navigate(['/workbench/result/'+page, this.projectid]);
      } else {
        this.refreshData('result', page);
      }
    }
  }

  onClickReport() {
    this.inputData = this.commonService.getInputData();
    if (this.inputData.menu_check.running) {
      this.init_load = 'report';
      this.router.navigate(['/workbench/report/', this.projectid]);
    } else {
      this.refreshData('report');
    }
  }

  onClickExpert(id: any) {
    this.inputData = this.commonService.getInputData();
    if (this.inputData.menu_check.material) {
      this.router.navigate(['/workbench/boundary/faces']);
      setTimeout(()=>{
        this.spinner.show();
        this.router.navigate(['/workbench/boundary/'+id+'/face/', this.projectid]);
      }, 80);
    }
  }

}
