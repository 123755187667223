/* tslint:disable */
import { SettingVtk } from './setting-vtk';
import { TypeAny } from './type-any';
import { Folder } from './folder';

/**
 */
export class Project {
    id?: number;
    uuid?: string;
    title?: string;
    description?: string;
    thumb?: string;
    params?: {};
    setting_vtk?: SettingVtk[];
    setting_vtk_default?: SettingVtk[];
    status?: number;
    stop_status?: number;
    is_update?: number;
    page?: number;
    limit?: number;
    search?: string;
    sort?: string;
    type?: string;
    file?: string;
    progressing?: {};
    project_image?: TypeAny[];
    projectImage?: TypeAny[];
    projectResult?: TypeAny[];
    timeRunning?: TypeAny[];
    folder?: Folder;
    created_at?: string;
    updated_at?: string;
    parent_id?: number;
    parentId?: number;
    is_report?: number;
}
