import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../api/services';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.css']
})
export class SignoutComponent implements OnInit, AfterViewInit {

  constructor(private apiAuths: AuthService, private router: Router) {}

  ngOnInit() {
    clearTimeout(JSON.parse(localStorage.getItem('timelogin')));
  }

  ngAfterViewInit() {
    this.apiAuths.logout().subscribe(
      res => {
        localStorage.clear();
        this.router.navigate(['/auth/signin']);
      }
    );
  }
}
