/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Project } from '../models/project';
import { TypeAny } from '../models/type-any';


@Injectable()
export class DashboardsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param type - undefined
   * @param status - undefined
   * @param sort - undefined
   * @param search - undefined
   * @param parent_id - undefined
   * @param page - undefined
   * @param limit - undefined
   */
  dashboardsResponse(params: DashboardsService.DashboardsParams): Observable<HttpResponse<Project[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set("type", params.type.toString());
    if (params.status != null) __params = __params.set("status", params.status.toString());
    if (params.sort != null) __params = __params.set("sort", params.sort.toString());
    if (params.search != null) __params = __params.set("search", params.search.toString());
    if (params.parentId != null) __params = __params.set("parent_id", params.parentId.toString());
    if (params.page != null) __params = __params.set("page", params.page.toString());
    if (params.limit != null) __params = __params.set("limit", params.limit.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/projects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Project[] = null;
        _body = _resp.body as Project[]
        return _resp.clone({body: _body}) as HttpResponse<Project[]>;
      })
    );
  }

  /**
   * @param type - undefined
   * @param status - undefined
   * @param sort - undefined
   * @param search - undefined
   * @param parent_id - undefined
   * @param page - undefined
   * @param limit - undefined
   */
  dashboards(params: DashboardsService.DashboardsParams): Observable<Project[]> {
    return this.dashboardsResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   */
  getAboutResponse(): Observable<HttpResponse<TypeAny[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny[] = null;
        _body = _resp.body as TypeAny[]
        return _resp.clone({body: _body}) as HttpResponse<TypeAny[]>;
      })
    );
  }

  /**
   */
  getAbout(): Observable<TypeAny[]> {
    return this.getAboutResponse().pipe(
      map(_r => _r.body)
    );
  }}

export module DashboardsService {
  export interface DashboardsParams {
    type?: string;
    status?: number;
    sort?: string;
    search?: string;
    parentId?: number;
    page?: number;
    limit?: number;
  }
}
