import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ProjectsService, FoldersService, DashboardsService } from '../../api/services';
import { Project, Folder } from '../../api/models';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../shared/common.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header-project',
  templateUrl: './header-project.component.html',
  styleUrls: ['./header-project.component.css']
})
export class HeaderProjectComponent implements OnInit {

  @ViewChild('copyProjectModal') public copyProjectModal: ModalDirective;
  @ViewChild('aboutModal') public aboutModal: ModalDirective;

  public projectid: any = 0;
  public projectForm: Project;
  @Input() projectname: string;
  public security: any;
  public avatar: any;
  public domainBO: any;
  public folders: Folder[] = [];
  public about: any;
  public ws: any;

  constructor(
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private projectsService: ProjectsService,
    private router: Router,
    private foldersService: FoldersService,
    private commonService: CommonService,
    private dashboardsService: DashboardsService,
  ) {
    this.projectForm = new Project();
    this.about = {
      logo: null,
      version: null,
      product_of: null,
      developed_by: null,
      address: null,
      email: null,
      website: null,
      release_notes: null
    }
  }

  ngOnInit() {
    let url = this.router.url.split('/');
    this.projectid = url[url.length - 1];
    
    this.security = this.commonService.getUser();
    this.avatar = environment.link_api+'/storage/'+this.security.avatar;
    this.domainBO = environment.link_api + '/admin/login?token=' + localStorage.getItem('token');
  }

  showCopy() {
    this.getFolders();
    this.getProjectById();
  }

  getFolders() {
    this.spinner.show();
    this.foldersService.getFolders({}).subscribe(
      res => {
        this.spinner.hide();
        this.folders = res['data'];
      }
    );
  }

  getProjectById() {
    //this.spinner.show();
    let params: ProjectsService.GetProjectByIdParams = {
      projectId: this.projectid,
      include: ''
    };
    this.projectsService.getProjectById(params).subscribe(
      res => {
        //this.spinner.hide();
        let project = res['data'];
        this.projectForm.title = project.title;
        this.projectForm.description = project.description;
        this.projectForm.parent_id = project.parent_id;
        this.copyProjectModal.show();
      }
    );
  }

  copyProject() {
    if (!this.projectForm.title) {
      swal('Warning!', this.translate.instant('project.title.required'), 'warning');
      return;
    }
    if (!this.projectForm.description) {
      swal('Warning!', this.translate.instant('project.description.required'), 'warning');
      return;
    }
    this.spinner.show();
    let params: ProjectsService.CopyProjectParams = {
      projectId: this.projectid,
      body: this.projectForm
    };
    this.projectsService.copyProject(params).subscribe(
      res => {
        if (res['success'] == 2) {
          this.createSocket(res['data']['uuid']);
        } else {
          this.spinner.hide();
          this.copyProjectModal.hide();
          this.router.navigate(['/manager/project', res['data']['uuid']]);
        }
      },
      err => {
        this.spinner.hide();
        if (err instanceof HttpErrorResponse) {
          swal({type: 'error', title: 'Error!', text: err.error.message});
        }
      }
    );
  }

  createSocket(projectid) {
    let urlSocket = environment.web_socket + '/' + projectid;
    this.ws = new WebSocket(urlSocket);
    this.ws.onopen = (event) => {
      console.log("Socket has been opened!");
    };
    this.ws.onmessage = (event) => {
      let data_socket = JSON.parse(event.data);
      if (data_socket.status == "upload_mesh") {
        this.spinner.hide();
        this.copyProjectModal.hide();
        this.router.navigate(['/manager/project', projectid]);
      } else {
        this.spinner.hide();
        this.copyProjectModal.hide();
        swal({type: 'error', title: 'Error!', text: this.translate.instant('copy_file.error')});
      }
    };
    this.ws.onerror = function (event) {
      this.spinner.hide();
      this.copyProjectModal.hide();
      swal({type: 'error', title: 'Error!', text: this.translate.instant('copy_file.error')});
    };
  }

  showAbout() {
    this.spinner.show();
    this.dashboardsService.getAbout().subscribe(
      res => {
        res.forEach(element => {
          if (element['key'] == 'about.logo') {
            this.about.logo = environment.link_api + '/storage/' + element['value'];
          } else if (element['key'] == 'about.version') {
            this.about.version = element['value'];
          } else if (element['key'] == 'about.product_of') {
            this.about.product_of = element['value'];
          } else if (element['key'] == 'about.developed_by') {
            this.about.developed_by = element['value'];
          } else if (element['key'] == 'about.address') {
            this.about.address = element['value'];
          } else if (element['key'] == 'about.email') {
            this.about.email = element['value'];
          } else if (element['key'] == 'about.website') {
            this.about.website = element['value'];
          } else if (element['key'] == 'about.release_notes') {
            this.about.release_notes = element['value'];
          }
        });
        this.spinner.hide();
        this.aboutModal.show();
      }
    );
  }

  viewDoc(download_link: any) {
    let pdfLink = environment.link_api + '/storage/' + download_link;
    window.open(pdfLink, '_blank');
  }

}
