/* tslint:disable */
import { Project } from './project';

/**
 */
export class Document {
    id?: number;
    title?: string;
    project_id?: number;
    project?: Project[];
    type?: number;
    user_id?: number;
    file?: string;
    can_copy?: number;
}
