/* tslint:disable */

/**
 */
export class SettingVtk {
    page?: string;
    representationSelectorName?: string;
    representationSelectorValue?: string;
    presetSelectorName?: string;
    presetSelectorValue?: string;
    colorByOptionsFullValue?: string;
    colorByOptionsName?: string;
    colorByOptionsValue?: string;
    compOptsName?: string;
    compOptsValue?: number;
    opacityValue?: number;
    minRangeValue?: string;
    maxRangeValue?: string;
    viewUps?: void[];
    positions?: void[];
    focalPoints?: void[];
}
