import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { AppHeaderComponent } from './app-header/app-header.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
//import { LaddaModule } from 'angular2-ladda';
import { FormsModule } from '@angular/forms';
import { CommonService } from './common.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { HeaderProjectComponent } from './header-project/header-project.component';



@NgModule({
  imports: [
    CommonModule,
    SharedRoutingModule,
    ModalModule,
    // LaddaModule.forRoot({
    //   style: 'slide-left',
    // }),
    FormsModule,
    BsDatepickerModule.forRoot(),
    TranslateModule
  ],
  declarations: [
    AppHeaderComponent,
    AppFooterComponent,
    HeaderProjectComponent,
  ],
  providers: [
    CommonService
  ],
  exports: [
    AppHeaderComponent,
    AppFooterComponent,
    HeaderProjectComponent,
    TranslateModule
  ]
})
export class SharedModule { }
