import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { environment } from '../../../environments/environment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DashboardsService } from '../../api/services';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})
export class AppHeaderComponent implements OnInit {

  @ViewChild('aboutModal') public aboutModal: ModalDirective;

  public security: any = null;
  public avatar: any = null;
  public domainBO: any = null;
  public about: any;

  constructor(
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private dashboardsService: DashboardsService,
  ) {
    this.about = {
      logo: null,
      version: null,
      product_of: null,
      developed_by: null,
      address: null,
      email: null,
      website: null,
      release_notes: null
    }
  }

  ngOnInit() {
    this.security = this.commonService.getUser();
    if (this.security) {
      this.avatar = environment.link_api+'/storage/'+this.security.avatar;
      this.domainBO = environment.link_api + '/admin/login?token=' + localStorage.getItem('token');
    }
  }

  showAbout() {
    this.spinner.show();
    this.dashboardsService.getAbout().subscribe(
      res => {
        res.forEach(element => {
          if (element['key'] == 'about.logo') {
            this.about.logo = environment.link_api + '/storage/' + element['value'];
          } else if (element['key'] == 'about.version') {
            this.about.version = element['value'];
          } else if (element['key'] == 'about.product_of') {
            this.about.product_of = element['value'];
          } else if (element['key'] == 'about.developed_by') {
            this.about.developed_by = element['value'];
          } else if (element['key'] == 'about.address') {
            this.about.address = element['value'];
          } else if (element['key'] == 'about.email') {
            this.about.email = element['value'];
          } else if (element['key'] == 'about.website') {
            this.about.website = element['value'];
          } else if (element['key'] == 'about.release_notes') {
            this.about.release_notes = element['value'];
          }
        });
        this.spinner.hide();
        this.aboutModal.show();
      }
    );
  }

  viewDoc(download_link: any) {
    let pdfLink = environment.link_api + '/storage/' + download_link;
    window.open(pdfLink, '_blank');
  }

}
