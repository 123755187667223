/* tslint:disable */

/**
 */
export class Material {
    id?: number;
    name?: string;
    user_id?: number;
    active?: boolean;
    default?: boolean;
    behavior?: string;
    young_modulus?: number;
    poisson_raito?: number;
    yield_stress?: number;
    delta_sif_threshold?: number;
    fracture_Toughness?: number;
    fatigue_law?: {};
    fatigue_coeff_table?: {};
    fatigue_coeff_exp?: {};
}
