/* tslint:disable */
import { Role } from './role';

/**
 */
export class User {
    id?: number;
    email?: string;
    password?: string;
    confirm_password?: string;
    name?: string;
    avatar?: string;
    role?: Role;
}
