/* tslint:disable */

/**
 */
export class Udf {
    id?: number;
    title?: string;
    description?: string;
    function_name?: string;
    filename?: string;
    text?: string;
    udf_type?: string;
    user_id?: number;
    page?: number;
    limit?: number;
}
