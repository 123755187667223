// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`. AIzaSyAiOuKxmO0fR-azjwFPGiff04CtB15WIWQ

export const environment = {
    production: true,
    role_admin: 'qazWSX2edc3RFV98TzAIDfaY',
    role_manager: 'plmOJN2ijBGT34WRFdQFac',
    pagination_limit: 8,
    pagination_size: 5,
    link_api: 'https://recette.adm.fatcalc2.dfm-europe.com',
    web_socket: 'wss://recette.ws.fatcalc2.dfm-europe.com/projects',
    contact: 'contact@dfm-engineering.com',
    phone: '028 3715 8010',
    sorts: [
        {
            name: 'Name ascending',
            code: 'name',
            type: 'asc'
        },
        {
            name: 'Name descending',
            code: 'name',
            type: 'desc'
        },
        {
            name: 'Date modifed ascending',
            code: 'date',
            type: 'asc'
        },
        {
            name: 'Date modifed descending',
            code: 'date',
            type: 'desc'
        }
    ],
    filters: [
        { 
            name: "All",
            value: 0
        },
        { 
            name: "Running",
            value: 5
        },
        { 
            name: "Queue",
            value: 6
        }
    ],
    timer: 1500,
    limit_set_timeout: 300000,
    time_refresh_token: 3240000,
    version_default_data: '1.9'
};

/*
* In development mode, to ignore zone related error stack frames such as
* `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
* import the following file, but please comment it out in production mode
* because it will have performance impact when throw error
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
