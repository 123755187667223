<header class="py-3 border-bottom">
  <div class="container-fluid d-grid gap-3 align-items-center" style="grid-template-columns: 3fr 2fr;">
    <div class="dropdown">
      <a [routerLink]="['/manager/dashboard']" class="d-flex align-items-center col-lg-4 mb-2 mb-lg-0 link-dark text-decoration-none ">
        <img src="assets/image/logo-small.png">       
      </a>
    </div>

    <div class="d-flex align-items-center">
      <div class="w-100 me-3" role="search">

      </div>

      <div class="flex-shrink-0 dropdown">
        <a href="#" class="d-flex align-items-center link-dark text-decoration-none" data-toggle="dropdown"
          aria-expanded="false">
          <h6 class="p-2 mb-0"><b class="user-name">{{ security?.name }}</b></h6>
          <img src="{{ avatar }}" alt="mdo" width="32" height="32" class="rounded-circle img-logo">
        </a>
        <ul class="dropdown-menu text-small shadow">
          <li><a class="dropdown-item" [routerLink]="['/manager/dashboard']">Dashboard</a></li>
          <li><a class="dropdown-item" [routerLink]="['/manager/profile']">View profile</a></li>
          <li *ngIf="security && security.role == 'admin'"><a target="_blank" class="dropdown-item" [href]="domainBO">Admin</a></li>
          <li><a class="dropdown-item" [routerLink]="['/manager/document']">Documentations</a></li>
          <li><a class="dropdown-item cursor-pointer" (click)="showAbout()">About</a></li>
          <li><a class="dropdown-item" [routerLink]="['/auth/signout']">Log out</a></li>
        </ul>
      </div>
    </div>
  </div>
</header>
<div bsModal #aboutModal="bs-modal" class="modal fade" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header ">
        <h5 class="modal-title text-center text-uppercase">About</h5>
        <button type="button" class="btn-close close" data-dismiss="modal" aria-label="Close"
          (click)="aboutModal.hide()"></button>
      </div>
      <div class="modal-body about">
        <div class="form-group text-center mb-1">
          <img src="{{ about.logo }}" alt="logo" class="">
        </div>
        <div class="form-group text-center mb-4">
          Version <span>{{ about.version }}</span>
        </div>
        <div class="form-group text-center">
          Product of <span>{{ about.product_of }}</span>
        </div>
        <div class="form-group text-center">
          Developed by <span>{{ about.developed_by }}</span>
        </div>
        <div class="form-group text-center">
          {{ about.address }}
        </div>
        <div class="form-group text-center mt-2">
          Email: <a href="mailto:{{ about.email }}">{{ about.email }}</a>
        </div>
        <div class="form-group text-center">
          Website: <a href="{{ about.website }}" target="_blank">{{ about.website }}</a>
        </div>
        <div class="form-group text-center mt-2">
          <a class="release-note" (click)="viewDoc(about.release_notes)">Release notes</a>
        </div>
      </div>
    </div>
  </div>
</div>