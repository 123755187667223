/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Project } from '../models/project';
import { TypeAny } from '../models/type-any';
import { Face } from '../models/face';
import { Job } from '../models/job';
import { ParamSettingVtk } from '../models/param-setting-vtk';


@Injectable()
export class ProjectsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body - Created project object
   */
  createProjectResponse(body?: Project): Observable<HttpResponse<Project>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/projects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Project = null;
        _body = _resp.body as Project
        return _resp.clone({body: _body}) as HttpResponse<Project>;
      })
    );
  }

  /**
   * @param body - Created project object
   */
  createProject(body?: Project): Observable<Project> {
    return this.createProjectResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   * @param include - undefined
   */
  getProjectByIdResponse(params: ProjectsService.GetProjectByIdParams): Observable<HttpResponse<Project>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    if (params.include != null) __params = __params.set("include", params.include.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/projects/${params.projectId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Project = null;
        _body = _resp.body as Project
        return _resp.clone({body: _body}) as HttpResponse<Project>;
      })
    );
  }

  /**
   * @param projectId - undefined
   * @param include - undefined
   */
  getProjectById(params: ProjectsService.GetProjectByIdParams): Observable<Project> {
    return this.getProjectByIdResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   * @param body - Update project object
   */
  updateProjectResponse(params: ProjectsService.UpdateProjectParams): Observable<HttpResponse<Project>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/projects/${params.projectId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Project = null;
        _body = _resp.body as Project
        return _resp.clone({body: _body}) as HttpResponse<Project>;
      })
    );
  }

  /**
   * @param projectId - undefined
   * @param body - Update project object
   */
  updateProject(params: ProjectsService.UpdateProjectParams): Observable<Project> {
    return this.updateProjectResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   */
  deleteProjectResponse(projectId: string): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/projects/${projectId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param projectId - undefined
   */
  deleteProject(projectId: string): Observable<TypeAny> {
    return this.deleteProjectResponse(projectId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   * @param body - Copy project object
   */
  copyProjectResponse(params: ProjectsService.CopyProjectParams): Observable<HttpResponse<Project>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/projects/${params.projectId}/copy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Project = null;
        _body = _resp.body as Project
        return _resp.clone({body: _body}) as HttpResponse<Project>;
      })
    );
  }

  /**
   * @param projectId - undefined
   * @param body - Copy project object
   */
  copyProject(params: ProjectsService.CopyProjectParams): Observable<Project> {
    return this.copyProjectResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   * @param body - set Input object
   */
  setInputResponse(params: ProjectsService.SetInputParams): Observable<HttpResponse<Project>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/projects/${params.projectId}/params`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Project = null;
        _body = _resp.body as Project
        return _resp.clone({body: _body}) as HttpResponse<Project>;
      })
    );
  }

  /**
   * @param projectId - undefined
   * @param body - set Input object
   */
  setInput(params: ProjectsService.SetInputParams): Observable<Project> {
    return this.setInputResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param status - undefined
   * @param projectId - undefined
   * @param name - undefined
   */
  getResultResponse(params: ProjectsService.GetResultParams): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/projects/${params.projectId}/${params.status}/${params.name}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'arraybuffer'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param status - undefined
   * @param projectId - undefined
   * @param name - undefined
   */
  getResult(params: ProjectsService.GetResultParams): Observable<TypeAny> {
    return this.getResultResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param status - undefined
   * @param projectId - undefined
   * @param name - undefined
   */
  getCsvResponse(params: ProjectsService.GetCsvParams): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/projects/${params.projectId}/${params.status}/${params.name}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param status - undefined
   * @param projectId - undefined
   * @param name - undefined
   */
  getCsv(params: ProjectsService.GetCsvParams): Observable<TypeAny> {
    return this.getCsvResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   */
  stopFatcalResponse(projectId: string): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/projects/${projectId}/stop`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param projectId - undefined
   */
  stopFatcal(projectId: string): Observable<TypeAny> {
    return this.stopFatcalResponse(projectId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   */
  deleteProjectResultResponse(projectId: string): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/projects/${projectId}/result`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param projectId - undefined
   */
  deleteProjectResult(projectId: string): Observable<TypeAny> {
    return this.deleteProjectResultResponse(projectId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param meshUnit - undefined
   */
  getScaleFactorResponse(meshUnit: string): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/projects/scale-factor/${meshUnit}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param meshUnit - undefined
   */
  getScaleFactor(meshUnit: string): Observable<TypeAny> {
    return this.getScaleFactorResponse(meshUnit).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   * @param body - set Input object
   */
  uploadMeshResponse(params: ProjectsService.UploadMeshParams): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/projects/upload/mesh/${params.projectId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param projectId - undefined
   * @param body - set Input object
   */
  uploadMesh(params: ProjectsService.UploadMeshParams): Observable<TypeAny> {
    return this.uploadMeshResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   * @param boundary_name - undefined
   * @param boundary_id - undefined
   */
  getFacesResponse(params: ProjectsService.GetFacesParams): Observable<HttpResponse<Face>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    if (params.boundaryName != null) __params = __params.set("boundary_name", params.boundaryName.toString());
    if (params.boundaryId != null) __params = __params.set("boundary_id", params.boundaryId.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/projects/boundary/${params.projectId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Face = null;
        _body = _resp.body as Face
        return _resp.clone({body: _body}) as HttpResponse<Face>;
      })
    );
  }

  /**
   * @param projectId - undefined
   * @param boundary_name - undefined
   * @param boundary_id - undefined
   */
  getFaces(params: ProjectsService.GetFacesParams): Observable<Face> {
    return this.getFacesResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   * @param type - undefined
   */
  getLogResponse(params: ProjectsService.GetLogParams): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    if (params.type != null) __params = __params.set("type", params.type.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/projects/logs/${params.projectId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param projectId - undefined
   * @param type - undefined
   */
  getLog(params: ProjectsService.GetLogParams): Observable<TypeAny> {
    return this.getLogResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   */
  getJobAllResponse(): Observable<HttpResponse<Job>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/projects/process/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Job = null;
        _body = _resp.body as Job
        return _resp.clone({body: _body}) as HttpResponse<Job>;
      })
    );
  }

  /**
   */
  getJobAll(): Observable<Job> {
    return this.getJobAllResponse().pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param projectId - undefined
   * @param body - set Input object
   */
  saveSettingVtkResponse(params: ProjectsService.SaveSettingVtkParams): Observable<HttpResponse<TypeAny>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/projects/${params.projectId}/setting-vtk`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TypeAny = null;
        _body = _resp.body as TypeAny
        return _resp.clone({body: _body}) as HttpResponse<TypeAny>;
      })
    );
  }

  /**
   * @param projectId - undefined
   * @param body - set Input object
   */
  saveSettingVtk(params: ProjectsService.SaveSettingVtkParams): Observable<TypeAny> {
    return this.saveSettingVtkResponse(params).pipe(
      map(_r => _r.body)
    );
  }}

export module ProjectsService {
  export interface GetProjectByIdParams {
    projectId: string;
    include?: string;
  }
  export interface UpdateProjectParams {
    projectId: string;
    body?: Project;
  }
  export interface CopyProjectParams {
    projectId: string;
    body?: Project;
  }
  export interface SetInputParams {
    projectId: string;
    body?: Project;
  }
  export interface GetResultParams {
    status: string;
    projectId: string;
    name: string;
  }
  export interface GetCsvParams {
    status: string;
    projectId: string;
    name: string;
  }
  export interface UploadMeshParams {
    projectId: string;
    body?: TypeAny;
  }
  export interface GetFacesParams {
    projectId: string;
    boundaryName?: string;
    boundaryId?: number;
  }
  export interface GetLogParams {
    projectId: string;
    type?: string;
  }
  export interface SaveSettingVtkParams {
    projectId: string;
    body?: ParamSettingVtk;
  }
}
