import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration } from './api-configuration';

import { AuthService } from './services/auth.service';
import { BoundariesService } from './services/boundaries.service';
import { DashboardsService } from './services/dashboards.service';
import { ProjectsService } from './services/projects.service';
import { DocumentationsService } from './services/documentations.service';
import { FoldersService } from './services/folders.service';
import { MaterialsService } from './services/materials.service';
import { PermissionsService } from './services/permissions.service';
import { UdfsService } from './services/udfs.service';
import { UsersService } from './services/users.service';
import { WorkbenchsService } from './services/workbenchs.service';

/**
 * Module that provides instances for all API services
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
   AuthService,
   BoundariesService,
   DashboardsService,
   ProjectsService,
   DocumentationsService,
   FoldersService,
   MaterialsService,
   PermissionsService,
   UdfsService,
   UsersService,
   WorkbenchsService
  ],
})
export class ApiModule { }
