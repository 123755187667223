/* tslint:disable */
import { Units } from './units';

/**
 */
export class Unit {
    id?: number;
    name?: string;
    units?: Units;
}
